import React, { useEffect, useRef, useState } from 'react';
import { scaleLinear, scaleOrdinal, scaleBand } from 'd3-scale';
import { select, axisLeft } from 'd3';
import YScaleDialog from '../log-viewer/yscale-dialog';
import '../log-viewer/depth-track.scss';
import './image-depth-track.scss';

export default function DepthTrack(props: any) {
  const svgRef: any = useRef();

  const { startDepth, 
          endDepth,
          depthUnit, 
          minHeaderHeight,
          editMode,           
          trackTop, 
          trackHeight,
          depthMajorIntervals, 
          setDialogMajorIntervals, 
          showDepthGridLines, 
          setShowDepthGridlines,
          showDepthMinorIntervals,
          setShowDepthMinorIntervals,
          depthMinorIntervals,
          setDepthMinorIntervals } = props;

  const yTicks = +depthMajorIntervals;

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [showScaleModal, setShowScaleModal] = useState(false);

  function debounce(func: any, wait: any, immediate: any) {
    var timeout: any;
    return function (this: any) {
      var context = this,
        args = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  useEffect(() => {
    const handleWindowResize = debounce(function handleResize() {
      setWindowHeight(window.innerHeight);
    }, 250, null);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const parentWidth = 50;

  const margins = {
    top: 0,
    right: 20,
    bottom: 5,
    left: parentWidth,
  };

  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;


  const width = parentWidth - margins.left - margins.right;
  //const height = windowHeight - 266 - margins.top - margins.bottom;
  
  const height = trackHeight; // windowHeight - (minHeaderHeight + 284 + (editMode ? 42:-40)) - margins.top - margins.bottom;  
  
  const totalWidth = width + margins.left + margins.right;
  const totalHeight = height + margins.top + margins.bottom;
  
  const getYRange = () => {
    //const filteredDepths = depths.filter((d :any) => d > startDepth && d < endDepth);
    //return filteredDepths;    
    if (startDepth >= 0 && endDepth && endDepth > startDepth) {
      return [startDepth, endDepth];
    }

    return [startDepth, endDepth];    
  };

  var isMajorTick = function (d: any, index: any) {
    return Number.isInteger(d);
    if (!showDepthMinorIntervals) {      
      return true;
    }

    return (index % (+depthMinorIntervals + 1)) === 0;
  }

  var lookupDiscreteValue = function (d: any) {
    // if (depths.length > d) {
    //   return depths[d];
    // }

    return d;
  }

const renderChart = () => {
    const svg = select(svgRef.current);
    //const yScale = scaleBand()
    const yScale = scaleLinear()
    .domain(getYRange())
    .range([0, height]);

    let ticks = yTicks;
    
    if (showDepthMinorIntervals) {
      ticks += yTicks * (+depthMinorIntervals);
    }

    const yAxis: any = axisLeft(yScale).ticks(ticks)    
    .tickFormat((d: any, i : number) => {      
      return isMajorTick(d, i) ? lookupDiscreteValue(d).toString() : "";
    })  
    svg.select(".y-axis").selectAll("*").remove();
    
    var y_axis = svg.select(".y-axis").call(yAxis);

    y_axis.selectAll("g")
        .filter(function (d, i) {
          return !isMajorTick(d, i);
        })
        .classed("image-minor-tick", true);
    
    svg.select(".unit")  
    .attr("transform",
          "translate(" + ((width/2) - 5)  + " ," + 
                        (-10) + ")")
    .style("text-anchor", "middle")  
    .style("font-size", "10px")  
    .style("fill", "grey")
    svg.select(".unit-text").text(depthUnit === 1 ? "m" : "ft");
}
  useEffect(() => {
    renderChart();
  }, [ windowHeight, 
      height,
      startDepth, 
      endDepth, 
      depthUnit, 
      minHeaderHeight, 
      editMode, 
      depthMajorIntervals, 
      showDepthGridLines,
      depthMinorIntervals,
      showDepthMinorIntervals]);

  const handleOpenCurveModal = () => {   
    setShowScaleModal(true);
  }

  const handleCloseScaleModal = () => {
    setShowScaleModal(false);   
   
  }
  
  const handleOkScaleModal = () => {
    setShowScaleModal(false);            
   
  }

  const onScaleFormSubmit = (e: any) => {
    e.preventDefault();
    handleOkScaleModal();
  }

  return (
    <div className="depth-track image-depth-track">
      {editMode && 
        <div className="is-centered button-column">
          <button className="button is-small" onClick={handleOpenCurveModal}>
              <span className="material-icons rotated" title="Set curve attributes">
                insights
              </span>     
          </button>
        </div>    
      }
        <div className={`svg-imagedepthtrack`} style={{marginTop: trackTop - (editMode ? 42 : 0)}}>
          {showDepthGridLines &&
            <svg width={totalWidth}
              height={totalHeight}>      
              <g ref={svgRef} transform={`translate(${margins.left}, ${margins.top})`}>
                <g className="unit" >
                    <text className="unit-text"></text>
                </g>
                <g className="y-axis" />
              </g>
            </svg>
          }
        </div>
        <YScaleDialog showScaleModal={showScaleModal} 
                      handleCloseScaleModal={handleCloseScaleModal}
                      handleOkScaleModal={handleOkScaleModal}
                      onScaleFormSubmit={onScaleFormSubmit}
                      majorIntervals={depthMajorIntervals}
                      setDialogMajorIntervals={setDialogMajorIntervals}
                      showDepthGridLines={showDepthGridLines}
                      setShowDepthGridlines={setShowDepthGridlines}
                      showDepthMinorIntervals={showDepthMinorIntervals}
                      setShowDepthMinorIntervals={setShowDepthMinorIntervals}
                      minorIntervals={depthMinorIntervals}
                      setMinorIntervals={setDepthMinorIntervals} />
    </div>
  );  
}
