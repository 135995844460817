import { Link } from 'react-router-dom';
import CheckboxField from '../../../components/Input/checkbox-field';
import TextField from '../../../components/Input/text-field';

const EditImageTypeForm = ({ 
    name,
    setName,
    subFolder,
    setSubFolder,
    fileName,
    setFileName,
    legendName,
    setLegendName,
    legendRollover,
    setLegendRollover,
    canAdjustImage,
    setCanAdjustImage,
    isCuttings,
    setIsCuttings,
    shouldCalculateAverageColor,
    setShouldCalculateAverageColor,
    readOnly,
    onSaveImageType,
    onDelete
  }: any) => {  

const FilenNameInfo = <div>This is the text that is looked for in the image file name.<br/>
                    For example "fe-carbonate-vnir %"</div>;

const LegendInfo = <div>If the image has a legend, then this is the text comes after the file name which shows it is the legend. <br/>
                        For example fe-carbonate-vnir % hole scale bar.png would be "  hole scale bar".</div>;


    return (
            <div className="columns">
              <div className="column is-one-quarter">

                <TextField
                  type="text" 
                  label="Name" 
                  value={name} 
                  onChange={setName}
                  readOnly={readOnly} />

                <TextField                
                  label="Sub Folder" 
                  info="This is the folder used to store the images on the server. Changing this will break existing images."
                  value={subFolder} 
                  onChange={setSubFolder}
                  readOnly={readOnly} />

                <TextField                
                  label="File Name" 
                  info={FilenNameInfo}
                  value={fileName} 
                  onChange={setFileName}
                  readOnly={readOnly} />

                <TextField                
                  label="Legend Name" 
                  info={LegendInfo}
                  value={legendName} 
                  onChange={setLegendName}
                  readOnly={readOnly} />

                <CheckboxField
                  label="Legend Rollover" 
                  info="If the image has a legend, then this determines if the image is show as a rollover or in the info panel."
                  checked={legendRollover} 
                  onChange={setLegendRollover}
                  readOnly={readOnly} />

                <CheckboxField                
                  label="Can Adjust Image" 
                  info="This determines if image filters can be applied to the image in the image viewer."
                  checked={canAdjustImage} 
                  onChange={setCanAdjustImage}
                  readOnly={readOnly} />

                <CheckboxField                                
                  label="Is Cuttings" 
                  info="This will stack images on top of each other."
                  checked={isCuttings} 
                  onChange={setIsCuttings}
                  readOnly={readOnly} />

                <CheckboxField                
                  label="Should calculate average color" 
                  info="During the scaling process the average color will be generated. This is used mainly for cuttings"
                  checked={shouldCalculateAverageColor} 
                  onChange={setShouldCalculateAverageColor}
                  readOnly={readOnly} />
                                               
                <div className="field is-grouped mt-3">
                    <div className="control">
                        <Link className='button' to={`/admin/image-type/list`}>Cancel</Link>
                    </div>
                    {!readOnly && 
                        <div className="control">
                            <button className="button is-link" type="submit" onClick={onSaveImageType}>Save</button>
                        </div>        
                    }      
                    {onDelete && <div className="control">
                            <button className="button is-link is-danger" onClick={onDelete}>Delete</button>
                        </div>    
                    }                          
                </div>
              </div>             
        </div>
    )    
};

export default EditImageTypeForm;