import { floor } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import './image-column-viewer.scss';

export const originalImageWidth = 600;

const ImageColumnViewer = ({ zoomInPastMinimum,
  trackHeight,
  moved,
  zoom,
  setZoom,
  yOffset,
  updateDepth,
  mouseWheelZoom,
  ctrlKeyPressed,
  filter,
  setMetaData,
  metaData,
  scaleImageFileNames,
  scaledImageHeight,
  apiUrl,
  clientId,
  projectId,
  boreholeId,
  imageType,
  authToken, 
  isCuttings }: any) => {

  //const [zoom, setZoom] = useState<number>(0.5);
  const [isPanning, setPanning] = useState(false);
  const [position, setPosition] = useState({
    oldX: 0,
    oldY: 0,
    x: 0,
    y: 0
  });

  const ref = useRef<any>(null);

  // useEffect(() => {
  //  calculateDepth(position.y, zoom / 2);
  // },[]);

  useEffect(() => {
    if (yOffset != null) {
      setPosition({
        ...position,
        y: yOffset
      });
      calculateDepth(yOffset, zoom);
    }
  }, [yOffset]);

  // useEffect(() => {
  //   if (position != null && ref.current.clientHeight > 0) {
  //     let newY = position.y;
  //     const maxY = getMaxY();
  //     if (newY > 0) {
  //       newY = 0;
  //     }
  //     if (newY < maxY) {
  //       if (maxY < 0) {
  //         newY = maxY;
  //       } else {
  //         newY = 0;      
  //       }
  //     }

  //     if (newY != position.y) {
  //       setPosition({
  //         ...position,
  //         y: newY
  //       });
  //       calculateDepth(yOffset, zoom);
  //     }
  //   }
  // }, [position]);

  const onMouseDown = (e: any) => {
    e.preventDefault();
    setPanning(true);
    setPosition({
      ...position,
      oldX: e.clientX,
      oldY: e.clientY
    });
  };

  const onMouseMove = (event: any) => {
    if (!isPanning) {
      var columns = getColumns(zoom);
      var imageHeight = (scaledImageHeight / columns) + 2;

      let rect = ref.current.getBoundingClientRect();
      let x = event.clientX - rect.left; //x position within the element.
      let y = event.clientY - rect.top;  //y position within the element.

      var cursorPoistion = y;
      var cursorDepth = Math.floor((cursorPoistion / imageHeight)) * columns;//(((-position.y + y -(imageHeight / 2)) / imageHeight) * columns);

      var xOffset = x * columns / originalImageWidth;

      // this needs to be worked out using new method

      var sectionNumber = cursorDepth + Math.floor(xOffset) + 1;
      var depth = Math.round((cursorDepth + xOffset) * 100) / 100;
      setMetaData(sectionNumber, xOffset - Math.floor(xOffset));
    }
  };

  const getColumns = (zoom: number) => {
    return (getBaseLog((1 / zoom), 2) + 1);
  }

  const getMaxY = (zoom: number) =>  {
    var imageHeight = (scaledImageHeight / getColumns(zoom)) + 2;
    return trackHeight - ref.current.clientHeight - trackHeight + imageHeight;
  }

  useEffect(() => {
    const mouseup = () => {
      setPanning(false);
    };

    const mousemove = (event: any) => {
      if (isPanning) {
        let newY = position.y + event.clientY - position.oldY;
        
        const maxY = getMaxY(zoom);
        if (newY > 0) {
          newY = 0;
        }
        if (newY < maxY) {
          if (maxY < 0) {
            newY = maxY;
          } else {
            // don't move
            //return;            
          }
        }

        setPosition({
          ...position,
          x: position.x,
          y: newY,
          oldX: event.clientX,
          oldY: event.clientY,
        });
        moved({ yOffset: newY }, true);
        calculateDepth(newY, zoom);
      }
    };

    window.addEventListener('mouseup', mouseup);
    window.addEventListener('mousemove', mousemove);

    return () => {
      window.removeEventListener('mouseup', mouseup);
      window.removeEventListener('mousemove', mousemove);
    };
  });

  const onWheel = (event: any) => {
    if (!mouseWheelZoom && !ctrlKeyPressed) {
      var bottom = -ref.current.clientHeight + trackHeight;

      var panAmount = event.deltaY > 0 ? -20 : 20;

      var newY = position.y += panAmount;
      if (newY < bottom) {
        newY = bottom > 0 ? 0 : bottom;
      }

      if (newY > 0) {
        newY = 0;
      }
      setPosition({
        ...position,
        y: newY
      });
      
      moved({ yOffset: newY }, true);
      calculateDepth(newY, zoom);

    } else {
      if (event.deltaY < 0) {
        if (zoom < 0.5) {                    
          const currentDepth = calculateSectionNumber(position.y, zoom);
          let newY = calculateOffset(currentDepth, zoom * 2);
          // const maxY = getMaxY(zoom * 2);
          // if (newY < maxY) {
          //   if (maxY < 0) {
          //     newY = maxY;
          //   } else {
          //     newY = 0;      
          //   }
          // }

          setPosition({
            ...position,
            y: newY
          });
          moved({ yOffset: newY, zoom: zoom * 2 }, true);
          calculateDepth(newY, zoom * 2);
          //setZoom(zoom * 2);
        } else {
          zoomInPastMinimum(calculateStartDepth(position.y, zoom));
        }
      } else {
        
        // work out new offset given the same start depth
        const currentDepth = calculateSectionNumber(position.y, zoom);        
        let newY = 0;
        // if (ref.current.clientHeight < height) {
        //   newY = 0;
        // } else {
          newY = calculateOffset(currentDepth, zoom / 2);
          // const maxY = getMaxY(zoom / 2);          
          // if (newY < maxY) {
          //   if (maxY < 0) {
          //     newY = maxY;
          //   } else {
          //     newY = 0;      
          //   }
          // }
     //   }
        setPosition({
          ...position,
          y: newY
        });
        moved({ yOffset: newY, zoom: zoom / 2 }, true);
        calculateDepth(newY, zoom / 2);
        //setZoom(zoom / 2);
      }
    }
  }

  const calculateStartDepth = (y: any, zoom: any) => {
    // var columns = getColumns();
    //var imageHeight = (originalImageHeight / columns) + 2;
    //var startDepth = (((-y - (imageHeight / 2)) / imageHeight) * columns);

    return calculateSectionNumber(y, zoom);
  }

  const calculateSectionNumber = (y: number, zoom: any) => {    
    if (!metaData) {
      return 1;
    }
    
    const columns = getColumns(zoom);

    let pixelCount = 0;
    let imageIndex = 0;
    let lastSection =  metaData.imageSizes[0]?.section ?? 0;
    const absY = Math.abs(y);
    while (pixelCount < absY && imageIndex < metaData.imageSizes.length) {
      if (imageIndex % columns == 0) {
        const imageSize = metaData.imageSizes[imageIndex];
        if (imageSize == null) {
          debugger;
        }
        var imageHeight = calculateCurrentPixelHeight(columns);
        pixelCount += imageHeight;
        lastSection = imageSize.section;
      }

      imageIndex++;
    }

    if (imageIndex === metaData.imageSizes.length) {
      return lastSection;
    }

    const remainder = absY - pixelCount;
    const imageSize = metaData.imageSizes[imageIndex];
    return lastSection + (remainder / calculateCurrentPixelHeight(columns)) + 0.5;
  };

  function roundHalf(num: number) {
    return Math.round(num * 2) / 2;
  }

  const calculateOffset = (sectionNumber: number, zoom: any) => {
    const columns = getColumns(zoom);

    const previousImages = metaData.imageSizes.filter((i: any) => i.section <= sectionNumber);
    let offsetY = 0;
    previousImages.forEach((imageSize: any, index: number) => {
      if (index % columns == 0) {
        offsetY += roundHalf(calculateCurrentPixelHeight(columns));
      }
    });

    return -offsetY;
  };

  const calculateCurrentPixelHeight = (columns: number) => {    
    return (scaledImageHeight / columns) + 2;
  }

  const calculateDepth = (y: any, zoom: any) => {
    if (updateDepth) {
      var columns = getColumns(zoom);
      
      var imageHeight = (scaledImageHeight / columns) + 2;

      var startDepth = calculateStartDepth(y, zoom);
      var depthHeight = (trackHeight / imageHeight) * columns;
      updateDepth(startDepth, startDepth + depthHeight);
    }
  }

  function getBaseLog(y: any, x: any) {
    return Math.log(y) / Math.log(x);
  }

const imageWidth = 600 / (getBaseLog((1 / zoom), 2) + 1);
if (!imageWidth) {
  debugger;
}

const imageClass = isCuttings ? "cuttings-image" : "test-image";

  return (
    <div className="image-viewer-column" style={{ height: trackHeight, filter: filter }} onWheel={onWheel}  onMouseDown={onMouseDown}>
      <div ref={ref} className="image-viewer-column" onMouseMove={onMouseMove} style={{ transform: `translate(${position.x}px, ${position.y}px)` }}>
        {(scaleImageFileNames || []).map((fileName: any, index: number) => (          
            <div key={index} className={imageClass} style={{ width:`${originalImageWidth / getColumns(zoom)}px` }}>              
              <img key={index} 
                   loading="lazy" 
                   width={imageWidth} 
                   height={scaledImageHeight / getColumns(zoom)}
                   src={`${fileName}`} />
            </div>                      
        ))}

      </div>
    </div>
  );
};

export default ImageColumnViewer;