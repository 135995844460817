import Chart from '../../components/log-viewer/line-chart';
import CumulativeChart from '../../components/log-viewer/cumulative-chart';
import ImageTrack from '../../components/log-viewer/image-track';
import TadpoleChart from '../../components/log-viewer/tadpole-chart';
import Lithology from '../../components/log-viewer/lithology/lithology';
import TextTrack from '../../components/log-viewer/text-track/text-track';
import HorizontalBarChart from '../../components/log-viewer/horizontal-bar-chart';

export default function Tracks(props : any) {

    const { 
      clientId, 
      projectId, 
      boreholeId, 
      startDepth, 
      endDepth, 
      byBoreholeDepth,
      sortedTrackData,
      template,
      depthUnit,      
      trackTypes,
      editMode,
      depthMajorIntervals,
      showDepthGridLines,
      showDepthMinorIntervals,
      depthMinorIntervals,
      deletedCurves,
      selectedTrackCategory,
      setSelectedTrackCategory,
      trackHeaderClick,
      setMetaDataDepth,      
      selectLithology,
      addLithology,
      topMargin,
      availableHeight,
      onUpdateCurve,
      onUpdateCurves,
      onSetShowGridlines,
      onSetScaleType,
      onSetTrackWidth,
      headerHeight,
      minHeaderHeight,
      headerBottomPadding,
      windowSize,
      container,
      imageHeight,
      apiUrl,
      imageAuthToken,
      cumulativeHeaderHeight
     } = props;
     
    return (        
        <>
        
                {(sortedTrackData || []).map((track: any, index: number) => { 
                  if (!track) {
                    debugger;
                  }
                  
                  if (track.trackType === 0) {
                    return (
                      <>
                        {!track.noData &&
                        <div key={track.id} className="column flex-item">
                          <Chart 
                              id={track.id} 
                              track={track}
                              displayOrder={track.displayOrder}
                              trackWidth={track.trackWidth}
                              topMargin={topMargin}
                              availableHeight={availableHeight}
                              index={index} 
                              curves={track.curves}                                  
                              chartData={track} 
                              startDepth={startDepth} 
                              endDepth={endDepth} 
                              depthUnit={depthUnit}
                              showYAxis={false} 
                              updateCurve={onUpdateCurve}
                              updateCurves={onUpdateCurves}
                              lineColor={track.lineColor}
                              fillColor={track.fillColor}
                              pointColor={track.pointColor}
                              lineStyle={track.lineStyle}
                              fillStyle={track.fillStyle}                        
                              pointStyle={track.pointStyle}
                              lineSize={track.lineSize}
                              fillSize={track.fillSize}                        
                              pointSize={track.pointSize}                            
                              showLine={track.showLine}
                              showFill={track.showFill}
                              showPoints={track.showPoints}
                              xScaleMinimum={track.xScaleMinimum}
                              xScaleMaximum={track.xScaleMaximum}
                              manualScaleXMinimum={track.manualScaleXMinimum}
                              manualScaleXMaximum={track.manualScaleXMaximum}
                              majorIntervals={track.majorIntervals}
                              minorIntervals={track.minorIntervals}
                              manualMajorIntervals={track.manualMajorIntervals}
                              manualMinorIntervals={track.manualMinorIntervals}
                              showGridlines={track.showGridlines}
                              showValueAxisAnnotation={track.showValueAxisAnnotation}
                              scaleType={track.scaleType}                            
                              setShowGridlines={onSetShowGridlines}                            
                              setScaleType={onSetScaleType}
                              setTrackWidth={onSetTrackWidth}                              
                              trackTypes={trackTypes}
                              editMode={editMode}
                              depthMajorIntervals={depthMajorIntervals}
                              showDepthGridLines={showDepthGridLines}
                              deletedCurves={deletedCurves}
                              selectedTrackCategory={selectedTrackCategory}
                              setSelectedTrackCategory={setSelectedTrackCategory}
                              headerHeight={headerHeight}
                              minHeaderHeight={minHeaderHeight}
                              headerBottomPadding={headerBottomPadding}
                              depthMinorIntervals={depthMinorIntervals}
                              showDepthMinorIntervals={showDepthMinorIntervals}
                              windowWidth={windowSize.width}
                              trackHeaderClick={() => trackHeaderClick(track)}
                              parentRef={container}
                              setMetaDataDepth={setMetaDataDepth}
                            />          
                        </div> }
                      </>)

                    }  if (track.trackType === 8) {                      
                      return (
                        <>
                          {!track.noData &&
                          <div key={track.id} className="column flex-item">
                            <HorizontalBarChart 
                                id={track.id} 
                                track={track}
                                displayOrder={track.displayOrder}
                                trackWidth={track.trackWidth}
                                topMargin={topMargin}
                                availableHeight={availableHeight}
                                index={index} 
                                curves={track.curves}                                  
                                chartData={track} 
                                startDepth={startDepth} 
                                endDepth={endDepth} 
                                depthUnit={depthUnit}
                                showYAxis={false} 
                                updateCurve={onUpdateCurve}
                                updateCurves={onUpdateCurves}
                                lineColor={track.lineColor}
                                fillColor={track.fillColor}
                                pointColor={track.pointColor}
                                lineStyle={track.lineStyle}
                                fillStyle={track.fillStyle}                        
                                pointStyle={track.pointStyle}
                                lineSize={track.lineSize}
                                fillSize={track.fillSize}                        
                                pointSize={track.pointSize}                            
                                showLine={track.showLine}
                                showFill={track.showFill}
                                showPoints={track.showPoints}
                                xScaleMinimum={track.xScaleMinimum}
                                xScaleMaximum={track.xScaleMaximum}
                                manualScaleXMinimum={track.manualScaleXMinimum}
                                manualScaleXMaximum={track.manualScaleXMaximum}
                                majorIntervals={track.majorIntervals}
                                minorIntervals={track.minorIntervals}
                                manualMajorIntervals={track.manualMajorIntervals}
                                manualMinorIntervals={track.manualMinorIntervals}
                                showGridlines={track.showGridlines}
                                showValueAxisAnnotation={track.showValueAxisAnnotation}
                                scaleType={track.scaleType}                            
                                setShowGridlines={onSetShowGridlines}                            
                                setScaleType={onSetScaleType}
                                setTrackWidth={onSetTrackWidth}                              
                                trackTypes={trackTypes}
                                editMode={editMode}
                                depthMajorIntervals={depthMajorIntervals}
                                showDepthGridLines={showDepthGridLines}
                                deletedCurves={deletedCurves}
                                selectedTrackCategory={selectedTrackCategory}
                                setSelectedTrackCategory={setSelectedTrackCategory}
                                headerHeight={headerHeight}
                                minHeaderHeight={minHeaderHeight}
                                headerBottomPadding={headerBottomPadding}
                                depthMinorIntervals={depthMinorIntervals}
                                showDepthMinorIntervals={showDepthMinorIntervals}
                                windowWidth={windowSize.width}
                                trackHeaderClick={() => trackHeaderClick(track)}
                                parentRef={container}
                                setMetaDataDepth={setMetaDataDepth}
                              />          
                          </div> }
                        </>)
  
                      } else if (track.trackType === 1) {
                      return (
                        <div key={track.id} className="column flex-item">
                          <ImageTrack 
                            id={track.id} 
                            track={track}
                            imageHeight= {imageHeight}
                            availableHeight={availableHeight}
                            displayOrder={track.displayOrder}
                            width={track.width}
                            index={index}
                            depthUnit={depthUnit}
                            label={track.label} 
                            legend={track.legend}
                            legendRollover={track.legendRollover}
                            apiUrl={apiUrl} 
                            clientId={clientId}
                            projectId={projectId} 
                            boreholeId={track.boreholeId ?? boreholeId}
                            startDepth={startDepth} 
                            endDepth={endDepth} 
                            imageType={track.imageType} 
                            byBoreholeDepth={byBoreholeDepth} 
                            authToken={imageAuthToken}                                                       
                            editMode={editMode}
                            headerHeight={headerHeight}
                            minHeaderHeight={minHeaderHeight}
                            headerBottomPadding={headerBottomPadding}
                            topMargin={topMargin}
                            trackWidth={track.trackWidth}                          
                            setTrackWidth={onSetTrackWidth}
                            trackHeaderClick={() => trackHeaderClick(track)}
                            scrollIntoView={track.scrollIntoView}
                            isSelected={track.isSelected}
                            showValueAxisAnnotation={template?.showValueAxisAnnotation}
                            noImage={track.noImage}
                            needsRegenerating={track.needsRegenerating}
                            setMetaDataDepth={setMetaDataDepth}
                            depths={track.depths}
                            isCuttings={track.isCuttings}
                          />
                        </div>
                        )
                    } else  if (track.trackType === 3) { // cumulative
                      return (
                        <>
                          {!track.noData &&
                          <div key={track.id} className="column flex-item">
                            <CumulativeChart 
                                id={track.id} 
                                displayOrder={track.displayOrder}
                                trackWidth={track.trackWidth}
                                availableHeight={availableHeight}
                                topMargin={topMargin}
                                index={index} 
                                curves={track.curves}                                    
                                track={track} 
                                startDepth={startDepth} 
                                endDepth={endDepth} 
                                depthUnit={depthUnit}
                                showYAxis={false} 
                                updateCurve={onUpdateCurve}                       
                                lineColor={track.lineColor}
                                fillColor={track.fillColor}
                                pointColor={track.pointColor}
                                lineStyle={track.lineStyle}
                                fillStyle={track.fillStyle}                        
                                pointStyle={track.pointStyle}
                                lineSize={track.lineSize}
                                fillSize={track.fillSize}                        
                                pointSize={track.pointSize}                            
                                showLine={track.showLine}
                                showFill={track.showFill}
                                showPoints={track.showPoints}
                                xScaleMinimum={track.xScaleMinimum}
                                xScaleMaximum={track.xScaleMaximum}
                                manualScaleXMinimum={track.manualScaleXMinimum}
                                manualScaleXMaximum={track.manualScaleXMaximum}
                                majorIntervals={track.majorIntervals}
                                minorIntervals={track.minorIntervals}
                                manualMajorIntervals={track.manualMajorIntervals}
                                manualMinorIntervals={track.manualMinorIntervals}
                                showGridlines={track.showGridlines}
                                showValueAxisAnnotation={track.showValueAxisAnnotation}
                                scaleType={track.scaleType}                            
                                setShowGridlines={onSetShowGridlines}
                                setScaleType={onSetScaleType}
                                setTrackWidth={onSetTrackWidth}
                                trackTypes={trackTypes}
                                editMode={editMode}
                                headerHeight={cumulativeHeaderHeight}
                                minHeaderHeight={minHeaderHeight}
                                headerBottomPadding={headerBottomPadding}
                                depthMajorIntervals={depthMajorIntervals}
                                showDepthGridLines={showDepthGridLines}
                                selectedTrackCategory={selectedTrackCategory}
                                setSelectedTrackCategory={setSelectedTrackCategory}
                                depthMinorIntervals={depthMinorIntervals}
                                showDepthMinorIntervals={showDepthMinorIntervals}
                                trackHeaderClick={() => trackHeaderClick(track)}
                                parentRef={container}
                                setMetaDataDepth={setMetaDataDepth}
                              />          
                          </div> }
                        </>)
    
                      }
                      else  if (track.trackType === 4) { // tadpole                        
                        return (
                          <>
                            {!track.noData &&
                            <div key={track.id} className="column flex-item">
                              <TadpoleChart 
                                  id={track.id} 
                                  displayOrder={track.displayOrder}
                                  trackWidth={track.trackWidth}
                                  availableHeight={availableHeight}
                                  topMargin={topMargin}
                                  index={index} 
                                  curves={track.curves}                                    
                                  track={track} 
                                  startDepth={startDepth} 
                                  endDepth={endDepth} 
                                  depthUnit={depthUnit}
                                  showYAxis={false} 
                                  updateCurve={onUpdateCurve}                
                                  lineColor={track.lineColor}
                                  fillColor={track.fillColor}
                                  pointColor={track.pointColor}
                                  lineStyle={track.lineStyle}
                                  fillStyle={track.fillStyle}                        
                                  pointStyle={track.pointStyle}
                                  lineSize={track.lineSize}
                                  fillSize={track.fillSize}                        
                                  pointSize={track.pointSize}                            
                                  showLine={track.showLine}
                                  showFill={track.showFill}
                                  showPoints={track.showPoints}
                                  xScaleMinimum={track.xScaleMinimum}
                                  xScaleMaximum={track.xScaleMaximum}
                                  manualScaleXMinimum={track.manualScaleXMinimum}
                                  manualScaleXMaximum={track.manualScaleXMaximum}
                                  majorIntervals={track.majorIntervals}
                                  minorIntervals={track.minorIntervals}
                                  manualMajorIntervals={track.manualMajorIntervals}
                                  manualMinorIntervals={track.manualMinorIntervals}
                                  showGridlines={track.showGridlines}
                                  showValueAxisAnnotation={track.showValueAxisAnnotation}
                                  scaleType={track.scaleType}                            
                                  setShowGridlines={onSetShowGridlines}
                                  setScaleType={onSetScaleType}
                                  setTrackWidth={onSetTrackWidth}
                                  trackTypes={trackTypes}
                                  editMode={editMode}
                                  headerHeight={headerHeight}
                                  minHeaderHeight={minHeaderHeight}
                                  headerBottomPadding={headerBottomPadding}
                                  depthMajorIntervals={depthMajorIntervals}
                                  showDepthGridLines={showDepthGridLines}
                                  selectedTrackCategory={selectedTrackCategory}
                                  setSelectedTrackCategory={setSelectedTrackCategory}
                                  depthMinorIntervals={depthMinorIntervals}
                                  showDepthMinorIntervals={showDepthMinorIntervals}
                                  trackHeaderClick={() => trackHeaderClick(track)}
                                  parentRef={container}
                                  setMetaDataDepth={setMetaDataDepth}
                                />          
                            </div> }
                          </>)
      
                        }
                        else  if (track.trackType === 5) { // lithology                        
                          return (
                            <>
                              <div key={track.id} className="column flex-item">
                                <Lithology 
                                    id={track.id} 
                                    displayOrder={track.displayOrder}
                                    trackWidth={track.trackWidth}
                                    availableHeight={availableHeight}
                                    topMargin={topMargin}
                                    index={index} 
                                    curves={track.curves}                                    
                                    track={track} 
                                    startDepth={startDepth} 
                                    endDepth={endDepth} 
                                    depthUnit={depthUnit}
                                    showYAxis={false}                                   
                                    xScaleMinimum={track.xScaleMinimum}
                                    xScaleMaximum={track.xScaleMaximum}
                                    manualScaleXMinimum={track.manualScaleXMinimum}
                                    manualScaleXMaximum={track.manualScaleXMaximum}
                                    majorIntervals={track.majorIntervals}
                                    minorIntervals={track.minorIntervals}
                                    manualMajorIntervals={track.manualMajorIntervals}
                                    manualMinorIntervals={track.manualMinorIntervals}
                                    showGridlines={track.showGridlines}
                                    showValueAxisAnnotation={track.showValueAxisAnnotation}
                                    scaleType={track.scaleType}                            
                                    setShowGridlines={onSetShowGridlines}
                                    setScaleType={onSetScaleType}
                                    setTrackWidth={onSetTrackWidth}
                                    trackTypes={trackTypes}
                                    editMode={editMode}
                                    headerHeight={cumulativeHeaderHeight}
                                    minHeaderHeight={minHeaderHeight}
                                    headerBottomPadding={headerBottomPadding}
                                    depthMajorIntervals={depthMajorIntervals}
                                    showDepthGridLines={showDepthGridLines}
                                    depthMinorIntervals={depthMinorIntervals}
                                    showDepthMinorIntervals={showDepthMinorIntervals}
                                    trackHeaderClick={() => trackHeaderClick(track)}
                                    parentRef={container}
                                    setMetaDataDepth={setMetaDataDepth}
                                    selectLithology={selectLithology}
                                    addLithology={addLithology}
                                    trackEditMode={track.trackEditMode}
                                  />          
                              </div>
                            </>)      
                          }
                          else  if (track.trackType === 7) { // text
                            return (
                              <>
                                <div key={track.id} className="column flex-item">
                                <TextTrack 
                                    id={track.id} 
                                    displayOrder={track.displayOrder}
                                    trackWidth={track.trackWidth}
                                    availableHeight={availableHeight}
                                    topMargin={topMargin}
                                    index={index}                                   
                                    track={track} 
                                    startDepth={startDepth} 
                                    endDepth={endDepth} 
                                    depthUnit={depthUnit}
                                    showYAxis={false}                                   
                                    xScaleMinimum={track.xScaleMinimum}
                                    xScaleMaximum={track.xScaleMaximum}
                                    manualScaleXMinimum={track.manualScaleXMinimum}
                                    manualScaleXMaximum={track.manualScaleXMaximum}
                                    majorIntervals={track.majorIntervals}
                                    minorIntervals={track.minorIntervals}
                                    manualMajorIntervals={track.manualMajorIntervals}
                                    manualMinorIntervals={track.manualMinorIntervals}
                                    showGridlines={track.showGridlines}
                                    showValueAxisAnnotation={track.showValueAxisAnnotation}
                                    scaleType={track.scaleType}                            
                                    setShowGridlines={onSetShowGridlines}
                                    setScaleType={onSetScaleType}
                                    setTrackWidth={onSetTrackWidth}
                                    trackTypes={trackTypes}
                                    editMode={editMode}
                                    headerHeight={cumulativeHeaderHeight}
                                    minHeaderHeight={minHeaderHeight}
                                    headerBottomPadding={headerBottomPadding}
                                    depthMajorIntervals={depthMajorIntervals}
                                    showDepthGridLines={showDepthGridLines}
                                    depthMinorIntervals={depthMinorIntervals}
                                    showDepthMinorIntervals={showDepthMinorIntervals}
                                    trackHeaderClick={() => trackHeaderClick(track)}
                                    parentRef={container}
                                    setMetaDataDepth={setMetaDataDepth}
                                    selectLithology={selectLithology}
                                    addLithology={addLithology}
                                    trackEditMode={track.trackEditMode}
                                  />   
                                </div>
                              </>)      
                            }
                  }
                )}
           
           
        </>
    )
  }
  