import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import './image-track.scss';
import { ResizeCallbackData } from 'react-resizable';
import lodashDebounce from 'lodash.debounce';
import TrackWrapper from './track-wrapper';
import './track-header.scss';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { scaleLinear } from 'd3-scale';
import { de } from 'date-fns/locale';

const ImageTrack = (props: any) => {
    const { id,       
            track,
            topMargin,
            label, 
            legend,
            legendRollover,
            depthUnit,
            apiUrl, 
            clientId, 
            projectId, 
            boreholeId, 
            startDepth, 
            endDepth, 
            imageType, 
            byBoreholeDepth, 
            authToken, 
            imageHeight,            
            headerHeight,
            minHeaderHeight,
            trackWidth,
            setTrackWidth,
            scrollIntoView, 
            trackHeaderClick,
            isSelected,            
            needsRegenerating,
            setMetaDataDepth,
            depths
          } = props;

    const fieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {      
      if (scrollIntoView && fieldRef.current) {
        fieldRef.current.scrollIntoView({
          behavior: "smooth",
          block:"start",
          inline: "end"
        });
      }
    }, [scrollIntoView]);

    useEffect(() => {      
      setImageLoaded(true);
    }, [startDepth, endDepth, imageType]);

    
  const [mousePosition, setMousePosition] = useState({left:0, top:0});
  const [imageLoaded, setImageLoaded] = useState(true);
  const [sortedDepths, setSortedDepths] = useState<any[]>([]);

useEffect(() => {
  let sortedDepths: any[] = depths ?  Array.from(depths) : [];
  
  sortedDepths?.sort((a: any, b: any) => b.startDepth - a.startDepth);   

  if (depthUnit === 2) {
    
    function convertDepths(depths: any[]) {
      return depths.map(d => ({
        ...d,
        startDepth: d.startDepth * 3.2808,
        endDepth: d.endDepth * 3.2808
      }));
    }

    sortedDepths = convertDepths(sortedDepths);
  }

  setSortedDepths(sortedDepths);
console.log("sortedDepths", sortedDepths);
}, [depths, depthUnit]);


  const yScale = scaleLinear()
  .domain([0, imageHeight])
  .range([startDepth, endDepth]);

  const throttledListener = useRef<(e: React.MouseEvent<HTMLDivElement>, sortedDepths:any[]) => void>();

  throttledListener.current = useCallback(
    throttle((e: React.MouseEvent<HTMLDivElement>, sortedDepths: any[]) => {
      imageOnMouseMove(e, sortedDepths);
    }, 100), // 200 ms throttle delay (adjust as needed)
    []
  );

      const imageOnMouseMove = (event: any, sortedDepths: any[]) => {        
        const newPosition = {left: event.pageX -60, top: event.pageY - event.target.offsetTop - 60};        
        var bounds = event.target.getBoundingClientRect();  
        let y = event.clientY - bounds.top;
        if (y < 0) {
          y = 0;
        }    
        
        const scaledDepth = yScale(y);        
        //const foundDepth = sortedDepths.find((d: any) => isCuttings ? scaledDepth < d.depth : scaledDepth >= d.depth);
        const foundDepth = sortedDepths.find((d: any) => scaledDepth < d.endDepth  && scaledDepth >= d.startDepth);
        const depth = scaledDepth;
        setMousePosition(newPosition);
        let values: any[]= [];
        if (foundDepth) {          
          values.push({ displayName: "Filename", value: foundDepth.fileName })
        }
        setMetaDataDepth({ depth, values});
      };

      const imageOnMouseOut = (event: any) => {
        setMetaDataDepth(null);
      };

      const headerPadding = () => {
        
        const totalHeight = headerHeight - 12;
        const padding = minHeaderHeight - totalHeight;
        if (padding > 0) {
          return <div style={{minHeight: padding}}></div>
        }
        
        return null;
      };

      const onResizeDebounce = (e: SyntheticEvent, data: ResizeCallbackData) => {      
        if (data.size.width) {
          setTrackWidth(id, data.size.width);
        }
      };
    
      const onResize = useCallback(lodashDebounce(onResizeDebounce, 40), []);      

      //let marginTop = (legend && !legendRollover ? 72 : topMargin);
      let marginTop = topMargin - (legend && !legendRollover ? 11 : 0);

      const imageRef = useRef<any>();

      const imageError = () => {
        setImageLoaded(false);
      };

      function throttle<T extends (...args: any[]) => void>(func: T, delay: number) {
        let lastCall = 0;
        return (...args: Parameters<T>) => {
          const now = Date.now();
          if (now - lastCall >= delay) {
            lastCall = now;
            func(...args);
          }
        };
      }

    return (
      <>{imageLoaded && 
        <TrackWrapper trackWidth={trackWidth} 
          onResize={onResize}          
          minWidth={40}
          scrollIntoView={scrollIntoView}
          editMode={isSelected}
          trackClick={trackHeaderClick}>
          <>
                {headerPadding()}
                <div className={`has-text-centered track-label cumulative-chart-header ${isSelected ? "highlighted-track" : ""}`} style={{backgroundColor: track.color}}>
                  <div className="track-header cumulative-chart-curve" data-tooltip-id={`image-header-${track.id}`}>{label} {needsRegenerating && <span className="material-icons warning-icon track-header-icon" title={"Need regenerating"}>warning</span>}
                    {legend && !legendRollover && <div>
                      <img className="image-legend" src={`${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/legend?imageType=${imageType}&access_token=${authToken}`} />
                    </div>}            
                  </div>                  
                </div>         
                {createPortal(
                  <Tooltip
                      id={`image-header-${track.id}`}
                      className="reacttooltip"
                      html={label}
                  />,
                  document.body
                )}
                {startDepth != null && endDepth != null &&
                  <div style={{marginTop: marginTop }} className={`${legendRollover ? 'image-tooltip' : ''}`}>
                      <img src={`${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image?startDepth=${startDepth}&endDepth=${endDepth}&imageType=${imageType}&byBoreholeDepth=${byBoreholeDepth}&depthUnit=${depthUnit}&access_token=${authToken}`} 
                        className="borehole" 
                        onError={imageError}                     
                        style={{height: imageHeight, width: trackWidth}} 
                        onMouseMove={(e) => throttledListener.current?.(e, sortedDepths)} 
                        onMouseOut={imageOnMouseOut}
                        ref={imageRef}/>

                      {legend && legendRollover && <span style={{left:(mousePosition.left) + 'px' , top: (mousePosition.top) + 'px'}}>
                        <img src={`${apiUrl}client/${clientId}/project/${projectId}/borehole/${boreholeId}/image/legend?imageType=${imageType}&access_token=${authToken}`} />
                      </span>
                      }
                  </div>
                }
            </>
        </TrackWrapper>
      }</>
    )
}

export default ImageTrack;