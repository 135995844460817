import React, { useEffect, useState } from 'react';
import Errors from '../../../components/errors';
import Loader from '../../../components/loader';
import Form from './form';

const ImageTypeContainer = ({ title, imageType, onSave, isLoading, error, readOnly, onDelete }:any) => {
    
    const [name, setName] = useState('');
    const [subFolder, setSubFolder] = useState('');
    const [fileName, setFileName] = useState('');
    const [legendName, setLegendName] = useState('');
    const [legendRollover, setLegendRollover] = useState(false);
    const [canAdjustImage, setCanAdjustImage] = useState(false);
    const [isCuttings, setIsCuttings] = useState(false);
    const [shouldCalculateAverageColor, setShouldCalculateAverageColor] = useState(false);
    
    useEffect(() => {
        if (!isLoading && imageType) {
            setName(imageType.description);
            setSubFolder(imageType.subFolder);
            setFileName(imageType.fileName);
            setLegendName(imageType.legendName);
            setLegendRollover(imageType.legendRollover);
            setCanAdjustImage(imageType.canAdjustImage);
            setIsCuttings(imageType.isCuttings);
            setShouldCalculateAverageColor(imageType.shouldCalculateAverageColor);
        }
    },[isLoading, imageType]);

    const onUpdateImageType = (e: any) => {        
        e.preventDefault();
        onSave({description: name, subFolder, fileName, legendName, legendRollover, canAdjustImage, isCuttings, shouldCalculateAverageColor });
      };
          
    return (
        <section className="section">
            <div>
                <h1>{title}</h1>
                <Errors error={error}/>
                {isLoading && <Loader/>}

                {!isLoading && <Form name={name}
                    setName={setName}
                    subFolder={subFolder}
                    setSubFolder={setSubFolder}
                    fileName={fileName}
                    setFileName={setFileName}
                    legendName={legendName} 
                    setLegendName={setLegendName}
                    legendRollover={legendRollover} 
                    setLegendRollover={setLegendRollover}
                    canAdjustImage={canAdjustImage}
                    setCanAdjustImage={setCanAdjustImage}
                    onSaveImageType={onUpdateImageType}
                    onDelete={onDelete}
                    readOnly={readOnly}          
                    isCuttings={isCuttings}
                    setIsCuttings={setIsCuttings}
                    shouldCalculateAverageColor={shouldCalculateAverageColor}
                    setShouldCalculateAverageColor={setShouldCalculateAverageColor}
                    />
                }
            </div>
        </section>
    )    
};

export default ImageTypeContainer;